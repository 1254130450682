import React, { FC } from 'react'
import { Form } from 'components/presentational/form'
import { FormattedMessage } from 'react-intl'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useDispatch } from 'react-redux'
import {
  nextStepAction,
  selectRegistrationAgeAction,
} from 'actions/form/stepRegistrationAction'
import { DecisionItem } from 'components/page/StepRegistration/StepRegistration.styled'
import { DecisionButton } from 'components/presentational/button/DecisionButton'
import { SpaceSeparator } from 'components/presentational/separator'
import { NumberRange } from 'reducers/registration/stepRegistrationReducer'
import { maximumAge } from 'common/constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { selectAgePath } from 'components/page/StepRegistration/StepRegistration.paths'
import { StepContent } from 'components/page/StepRegistration/StepContent'

export const SelectAge: FC = () => {
  const dispatch = useDispatch()
  const { targetGender } = useShallowEqualSelector(
    ({ stepRegistration: { targetGender } }) => ({
      targetGender,
    })
  )
  const handleSelectAge = (range: NumberRange) => () => {
    dispatch(selectRegistrationAgeAction(range))
  }
  const handleSubmit = () => dispatch(nextStepAction(selectAgePath))
  return (
    <StepContent path={selectAgePath}>
      <Form id={'stepRegistration'} onSubmit={handleSubmit}>
        <StyledModalTitle>
          <FormattedMessage
            id={'registration.step.select_age'}
            defaultMessage={
              '{gender, select, M {Мужчину} F {Женщину} other {Людей}} какого возраста вы хотели бы найти?'
            }
            values={{ gender: targetGender }}
          />
        </StyledModalTitle>
        <DecisionItem>
          <DecisionButton
            onClick={handleSelectAge({ from: 18, to: 25 })}
            data-name={'gender-action'}
          >
            18 – 25
          </DecisionButton>
        </DecisionItem>
        <SpaceSeparator />
        <DecisionItem>
          <DecisionButton
            onClick={handleSelectAge({ from: 25, to: 30 })}
            data-name={'gender-action'}
          >
            25 – 30
          </DecisionButton>
        </DecisionItem>
        <SpaceSeparator />
        <DecisionItem>
          <DecisionButton
            onClick={handleSelectAge({ from: 30, to: maximumAge })}
            data-name={'gender-action'}
          >
            <FormattedMessage
              id={'registration.step.above_age'}
              defaultMessage={'Старше {value} лет'}
              values={{
                value: 30,
              }}
            />
          </DecisionButton>
        </DecisionItem>
        <SpaceSeparator />
      </Form>
    </StepContent>
  )
}
