import React, { FC, useRef, useState, ChangeEventHandler } from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { availableYears, months } from 'common/constants'
import { SpaceSeparator } from '../separator'
import { Consumer } from '../form'
import { SelectRef } from 'components/presentational/input'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'

const DateWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 2;
  flex-shrink: 0;
  min-width: 68px;
  text-transform: lowercase;
`

const DaySelect = styled.div`
  position: relative;
  flex-grow: 1.2;
`

const MonthSelect = styled.div`
  flex-grow: 1;
  position: relative;
`

const YearSelect = styled.div`
  flex-grow: 0.6;
  position: relative;
`

const now = new Date()

export const DateSelect: FC<{
  dayPlaceholder: string
  monthPlaceholder: string
  yearPlaceholder: string
  dayValue?: number
  monthValue?: number
  yearValue?: number
  dayName?: string
  monthName?: string
  yearName?: string
  message?: unknown
  disabled?: boolean
  required?: boolean
  onChange?: ChangeEventHandler<HTMLSelectElement>
}> = ({
  dayPlaceholder,
  monthPlaceholder,
  yearPlaceholder,
  dayValue,
  monthValue = now.getMonth(),
  yearValue = now.getFullYear(),
  dayName = 'day',
  monthName = 'month',
  yearName = 'year',
  message,
  onChange,
  ...rest
}) => {
  const { form } = useShallowEqualSelector(({ formReducer: { form } }) => ({
    form,
  }))
  const intl = useIntl()

  const dayRef = useRef<HTMLSelectElement | null>(null)
  const monthRef = useRef<HTMLSelectElement | null>(null)
  const yearRef = useRef<HTMLSelectElement | null>(null)

  const [valid, setValid] = useState(true)

  const handleInvalid = (event) => {
    event.preventDefault()
    updateValidity()
  }

  const handleChange = (event) => {
    updateValidity()
    onChange?.(event)
  }

  const updateValidity = () => setValid(isValid())

  const isValid = () => {
    if (dayRef && monthRef && yearRef) {
      return Boolean(
        dayRef.current?.validity.valid &&
          monthRef.current?.validity.valid &&
          yearRef.current?.validity.valid
      )
    }
    return true
  }

  const currentYear = now.getFullYear()

  const currentYearSelected = Number(yearValue) === currentYear
  const currentMonth = now.getMonth() + 1
  const currentMonthSelected = Number(monthValue) === currentMonth
  const currentDay = now.getDate() + 1

  const availableMonths = currentYearSelected
    ? months.filter((month) => month <= currentMonth)
    : months

  const days = Array(new Date(yearValue, Number(monthValue), 0).getDate())
    .fill(null)
    .map((_, index) => index + 1)

  const availableDays =
    currentYearSelected && currentMonthSelected
      ? days.filter((day) => day < currentDay)
      : days

  return (
    <>
      <DateWrapper>
        <DaySelect>
          <SelectRef
            placeholder={dayPlaceholder}
            value={dayValue}
            name={dayName}
            ref={dayRef}
            onInvalid={handleInvalid}
            onChange={handleChange}
            {...rest}
          >
            <option />
            {availableDays.map((value) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </SelectRef>
        </DaySelect>
        <SpaceSeparator size={16} />
        <MonthSelect>
          <StyledSelect
            placeholder={monthPlaceholder}
            value={monthValue}
            name={monthName}
            ref={monthRef}
            onInvalid={handleInvalid}
            {...rest}
            onChange={handleChange}
          >
            <option />
            {availableMonths.map((value) => (
              <option value={Number(value)} key={value}>
                {intl.formatDate(new Date(yearValue, Number(value), 0), {
                  month: 'long',
                })}
              </option>
            ))}
          </StyledSelect>
        </MonthSelect>
        <SpaceSeparator size={16} />
        <YearSelect>
          <SelectRef
            placeholder={yearPlaceholder}
            value={yearValue}
            name={yearName}
            ref={yearRef}
            onInvalid={handleInvalid}
            {...rest}
            onChange={handleChange}
          >
            <option />
            {availableYears.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </SelectRef>
        </YearSelect>
      </DateWrapper>
      <Consumer>
        {(formId) => {
          const elements = form[formId] || {}
          const dirty = Boolean(
            elements[dayName] && elements[monthName] && elements[yearName]
          )
          return <>{dirty && !valid && message}</>
        }}
      </Consumer>
    </>
  )
}

const StyledSelect = styled(SelectRef)`
  select {
    text-transform: lowercase;
  }
`
