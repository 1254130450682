import React, { FC } from 'react'
import { BaseButton } from 'components/presentational/button'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { Colors } from 'common-constants/colors'
import { ButtonProps } from 'components/presentational/button/button.types'

export const DecisionButtonDark: FC<ButtonProps> = (props) => {
  return (
    <ThemeProvider
      theme={
        ({
          background: Colors.backgroundLight,
          hoverBackground: Colors.backgroundHoverLight,
          color: Colors.dark,
          hoverColor: Colors.defaultAction,
          disabledColor: Colors.hoverColor,
          disabledBackground: Colors.defaultAction,
          activeColor: Colors.hoverColor,
          activeBackground: Colors.defaultAction,
          fontSize: 15,
          padding: '14px',
        } as unknown) as DefaultTheme
      }
    >
      <BaseButton {...props} />
    </ThemeProvider>
  )
}
