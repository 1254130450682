import { postApi } from 'api/fetchApi'
import { operations } from 'api/generated/registration_validation_birthdate'

type Operation = operations['registration_validate_birthdate_validateBirthdate']
type Response = Operation['responses']['204']['schema']
type Body = Operation['parameters']['body']['body']

export const validateBirthdateApi = (birthdate: string) => {
  return postApi<Response, Body>(
    '/registration/validateBirthdate' as 'registration/validateBirthdate',
    {
      birthdate,
    }
  )
}
