import React, { FC } from 'react'
import { Form } from 'components/presentational/form'
import { FormattedMessage } from 'react-intl'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useDispatch } from 'react-redux'
import {
  nextStepAction,
  toggleConstitutionAction,
} from 'actions/form/stepRegistrationAction'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { SubmitGroup } from 'components/page/StepRegistration/SubmitGroup'
import { GenericMenuItemIndex } from 'components/presentational/GenericMenuItemIndex/GenericMenuItemIndex'
import Checkbox from 'components/presentational/Checkbox'
import { Constitution } from 'common-constants/api6.types'
import { constitutionPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { StepContent } from 'components/page/StepRegistration/StepContent'

export const SelectConstitution: FC = () => {
  const dispatch = useDispatch()
  const {
    constitutions,
    variants = [],
    targetGender,
  } = useShallowEqualSelector(
    ({
      stepRegistration: {
        targetGender,
        constitutions,
        variants: { constitution: variants },
      },
    }) => ({ constitutions, variants, targetGender })
  )
  const handleChange = (value: Constitution) => {
    dispatch(toggleConstitutionAction(value, !constitutions.includes(value)))
  }
  const handleSubmit = () => dispatch(nextStepAction(constitutionPath))
  return (
    <StepContent path={constitutionPath}>
      <Form id={'stepRegistration'} onSubmit={handleSubmit}>
        <StyledModalTitle>
          <FormattedMessage
            id={'registration.step.physique'}
            defaultMessage={
              '{gender, select, M {Мужчин} F {Женщин} other {Людей}} какого телосложения вы ищите?'
            }
            values={{
              gender: targetGender,
            }}
          />
        </StyledModalTitle>
        {variants.map(({ name, value }, index) => (
          <GenericMenuItemIndex
            key={index}
            hideArrow={true}
            control={
              <Checkbox
                name={'dating-physique'}
                data-name={`dating-physique-${value}-action`}
                checked={constitutions.includes(value)}
                onChange={() => handleChange(value)}
              />
            }
          >
            {name}
          </GenericMenuItemIndex>
        ))}
        <SubmitGroup disabled={constitutions.length === 0} />
      </Form>
    </StepContent>
  )
}
