import React, { FC, PropsWithChildren } from 'react'
import {
  StyledModalWidthLimit,
  Wrapper,
} from 'components/layout/VideoLayout/VideoLayout.styled'
import { VideoBackground } from 'components/layout/VideoLayout/VideoBackground'
import { VideoHeader } from 'components/layout/VideoLayout/VideoHeader'
import { VideoFooter } from 'components/layout/VideoLayout/VideoFooter'
import { VideoModalSwitch } from 'components/layout/VideoLayout/VideoModalSwitch'
import { NotPhone } from 'components/presentational/layout/NotPhone'

export const VideoBackgroundModalLayout: FC<PropsWithChildren> = ({
  children,
}) => {
  return (
    <Wrapper id="VideoBackgroundModalLayout">
      <NotPhone>
        <VideoBackground />
      </NotPhone>
      <StyledModalWidthLimit>
        <NotPhone>
          <VideoHeader />
        </NotPhone>
        {children}
        <NotPhone>
          <VideoFooter showAppLinks={false} showLinks={false} />
        </NotPhone>
      </StyledModalWidthLimit>
      <VideoModalSwitch />
    </Wrapper>
  )
}
