import React, { FC, useMemo } from 'react'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { FormattedDate, FormattedMessage } from 'react-intl'
import { ageRestrictionPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { Age18PlusSvg } from 'components/presentational/svg/Age18PlusSvg'
import styled from 'styled-components'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { createAllowedDate } from 'functions/date/createAllowedDate'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`

const Description = styled.div`
  margin: 20px 70px 120px;
  text-align: center;
  line-height: 20px;
`

/**
 * Дизайн:
 * https://www.figma.com/file/diOCI6MeJlIl299WQtGrH5/Онбординги?node-id=2415%3A3231&t=E44yo6FxRkGprlCb-0
 * @constructor
 */
export const AgeRestriction: FC = () => {
  const { year, month, day } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { year, month, day },
      },
    }) => ({ year, month, day })
  )

  const allowedDate = useMemo(() => {
    return createAllowedDate(
      /** -1 потому что месяцы начинаются с нуля */
      new Date(Number(year), Number(month) - 1, Number(day))
    )
  }, [day, month, year])

  return (
    <StepContent path={ageRestrictionPath} navigationEnabled={false}>
      <StyledModalTitle>
        <FormattedMessage
          id={'registration.step.age_restriction.title'}
          defaultMessage="Наш сервис не доступен людям, которым ещё нет 18 лет"
        />
      </StyledModalTitle>

      <Content>
        <Age18PlusSvg />

        <Description>
          <FormattedMessage
            id={'registration.step.age_restriction.description'}
            defaultMessage="Возвращайся {date} – будем рады видеть тебя снова!"
            values={{
              date: (
                <FormattedDate
                  value={allowedDate}
                  day="numeric"
                  month="long"
                  year="numeric"
                />
              ),
            }}
          />
        </Description>
      </Content>
    </StepContent>
  )
}
