import { mambaFeaturesFlagsParsed } from 'api/api.constants'
import { RootState } from 'reducers/RootState'
import { useShallowEqualSelector } from './useShallowEqualSelector'
import { Gender } from 'common-constants/api6.types'
import { GQLGender } from 'generated-graphql/graphql'

export const useLgbtDetail = (
  gender?: Gender | GQLGender
): {
  lgbtModified?: boolean
  lgbtHidden?: boolean
  lgbtRestriction?: boolean
} => {
  return useShallowEqualSelector((state) => getLgbtDetail(state, gender))!
}

export const getLgbtDetail = (
  state: RootState,
  gender?: Gender | GQLGender
) => {
  // Фича 42 - Поддержка скрытия/модификации гейского функционала (#m5062)
  const detail = state.systemReducer.features?.[mambaFeaturesFlagsParsed.lgbt]
  // Фича 45 - Поддержка выбора пола (#m6296)
  const isLgbtExtended =
    state.systemReducer.features?.[mambaFeaturesFlagsParsed.lgbtExtended] === 0

  const authorized = state.authorizationReducer.authorized

  if (!authorized && !gender) {
    return {}
  }

  switch (detail) {
    case undefined:
      if (isLgbtExtended) {
        /** Если попали сюда, значит 42-я фича выключена, а 45-я включена. */
        return { lgbtRestriction: true }
      } else {
        return {}
      }
    case 0:
      return {}
    case 1:
      return { lgbtModified: true }
    case 2:
      return { lgbtHidden: true }

    default:
      console.error(`LGBT detail is not supported. LGBT detail: ${detail}`)
  }
}
