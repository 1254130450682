import React, { FC } from 'react'
import { StyledVisuallyHiddenText } from 'components/presentational/VisuallyHidden'
import { SocialIcon } from 'components/page/Boarding/SocialNetworks/SocialIcon'
import { SocialLink } from './VendorItem.styled'
import { VendorSocialList } from 'common-constants/vendorSocialList'
import { definitions } from 'api/generated/oauth-vendors'
import { useVendorItem } from './VendorItem.hooks'

export interface VendorItemProps {
  vendor: definitions['OAuthVendor']
  size?: number
}

export const VendorItem: FC<VendorItemProps> = ({ vendor, size }) => {
  const { isVkConnect, onVendorClick } = useVendorItem(vendor)
  const { url, name, icon } = vendor
  const vendorName = name as VendorSocialList

  return (
    <SocialLink
      target="_self"
      href={isVkConnect ? '#' : url}
      data-name={name}
      onClick={onVendorClick}
    >
      <SocialIcon name={vendorName} icon={icon} size={size} />
      <StyledVisuallyHiddenText>{vendorName}</StyledVisuallyHiddenText>
    </SocialLink>
  )
}
