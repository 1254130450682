import { stepRegisterWithEmailAction } from 'actions/form/stepRegisterWithEmailAction'
import { updateRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { FontSize } from 'common-constants/sizes'
import { SocialNetworksNext } from 'components/page/Boarding/SocialNetworksNext/SocialNetworksNext'
import { TitleText } from 'components/page/StepRegistration/step/TitleText'
import { LargeInput } from 'components/page/StepRegistration/StepRegistration.styled'
import { Button } from 'components/presentational/button'
import {
  Field,
  Form,
  FormAction,
  resolveFormFieldValue,
} from 'components/presentational/form'
import {
  AppInputChangeHandler,
  InvalidMessage,
} from 'components/presentational/input'
import { Content, ViewContent } from 'components/presentational/modal'
import ModalNavigation from 'components/presentational/ModalNavigation'
import { BackSvg } from 'components/presentational/svg/BackSvg'
import { EmailSvg } from 'components/presentational/svg/EmailSvg'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { UnexpectedServerErrorMessage } from 'components/presentational/UnexpectedServerErrorMessage'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, SyntheticEvent, useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import {
  StepRegistrationFieldList,
  stepRegistrationReducer,
} from 'reducers/registration/stepRegistrationReducer'
import styled, { css } from 'styled-components'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { PromoCodeDetailsLoadable } from 'components/page/PromoCode/PromoCodeDetailsLoadable'
import { media } from 'components/presentational'
import { isShowPromoCodeDetails } from 'components/page/PromoCode/promoCode.functions'
import { fetchVendorsListIfNeededAction } from 'actions/authorization/vendorsListAction'
import { finalStepPath } from '../StepRegistration.paths'
import { prevStepAction } from 'actions/form/stepRegistrationWithoutApiAction'

const messages = defineMessages({
  email: {
    id: 'app.email',
    defaultMessage: 'Электронная почта',
  },
  register: {
    id: 'app.register_email',
    defaultMessage: 'Регистрация с почтой',
  },
  register_vk: {
    id: 'app.register.vk',
    defaultMessage: 'Продолжить с ВК',
  },
})

export const Final: FC = () => {
  useReducersInsert({ stepRegistration: stepRegistrationReducer })

  const intl = useIntl()
  const dispatch = useDispatch()

  const {
    email,
    authorizationEmail,
    genericError,
    formErrors,
    promocodereg,
  } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { email },
        formErrors,
      },
      authorizationReducer: {
        genericError,
        form: { email: authorizationEmail },
      },
      abTest: { promocodereg },
    }) => ({
      email,
      authorizationEmail,
      genericError,
      formErrors,
      promocodereg,
    })
  )

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    dispatch(fetchVendorsListIfNeededAction())
    // dispatch(fetchRegistrationSearchResultAction())
  }, [dispatch])

  const handleChange: AppInputChangeHandler = (event) => {
    const { name, value } = resolveFormFieldValue(event.target)
    dispatch(
      updateRegistrationFieldAction(
        name as StepRegistrationFieldList,
        value as string
      )
    )
  }

  const handleBackClick = () => {
    dispatch(prevStepAction(finalStepPath))
  }

  const handleSubmit = async () => {
    setLoading(true)
    await dispatch(stepRegisterWithEmailAction())
    setLoading(false)
  }

  return (
    <>
      <Content $flexGrow={1}>
        <ModalNavigation
          historyBack={true}
          onBackClick={handleBackClick}
          icon={<BackSvg />}
        />
        <ViewContent>
          <Form id={'stepRegistration'} onSubmit={handleSubmit}>
            <FinalTitle>
              <TitleText />
            </FinalTitle>
            <>
              <TitleSmall>
                <FormattedMessage
                  id={'registration.step.email_enter'}
                  defaultMessage={'Введите электронную почту'}
                />
              </TitleSmall>
              <StyledField>
                <LargeInput
                  name={StepRegistrationFieldList.email}
                  type={'email'}
                  value={email}
                  placeholder={intl.formatMessage(messages.email)}
                  required
                  maxLength={500}
                  onChange={handleChange}
                  message={
                    <InvalidMessage>
                      <FormattedMessage id={'form.email_error'} />
                    </InvalidMessage>
                  }
                />
                {formErrors.email && (
                  <InvalidMessage>{formErrors.email}</InvalidMessage>
                )}
              </StyledField>
            </>
            <StyledFormAction>
              <Button type={'submit'} loading={loading} as="button">
                {!loading && <StyledEmailSvg />}
                {intl.formatMessage(messages.register)}
              </Button>
            </StyledFormAction>
            {genericError && (
              <InvalidMessage>
                <UnexpectedServerErrorMessage />
              </InvalidMessage>
            )}

            {isShowPromoCodeDetails(promocodereg) && (
              <PromoCodeDetailsLoadable styles={promoCodeDetailsStyles} />
            )}

            <TitleSmall>
              <FormattedMessage
                id={'registration.step.social_enter'}
                defaultMessage={'Или войдите через социальные сети'}
              />
            </TitleSmall>

            <SocialNetworksNext showAccept={true} />
          </Form>
        </ViewContent>
      </Content>
    </>
  )
}

const promoCodeDetailsStyles = css`
  margin-top: 40px;
  margin-bottom: 0;
  padding-top: 0;
  order: 1;

  ${media.phone`
    margin-top: 0;
    margin-bottom: 16px;
    text-align: left;
    order: 0;
  `};
`

const FinalTitle = styled(StyledModalTitle)`
  font-weight: normal;
  font-size: ${FontSize.titleLarge}px;
  padding-left: 10px;
  padding-right: 10px;
`

const TitleSmall = styled(StyledModalTitle)`
  margin-top: auto;
  font-weight: normal;
  font-size: ${FontSize.header}px;
`

const StyledField = styled(Field)`
  margin-top: 20px;
`

const StyledEmailSvg = styled(EmailSvg)`
  ${({ theme }) => theme.marginRight}: 11px;
`

const StyledFormAction = styled(FormAction)`
  margin-bottom: 30px;
`
