import {
  nextStepAction,
  updateRegistrationFieldAction,
} from 'actions/form/stepRegistrationAction'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { enterNamePath } from 'components/page/StepRegistration/StepRegistration.paths'
import { LargeInput } from 'components/page/StepRegistration/StepRegistration.styled'
import { SubmitGroup } from 'components/page/StepRegistration/SubmitGroup'
import {
  Field,
  Form,
  resolveFormFieldValue,
} from 'components/presentational/form'
import {
  AppInputChangeHandler,
  InvalidMessage,
} from 'components/presentational/input'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useEffect, useRef } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import { StepRegistrationFieldList } from 'reducers/registration/stepRegistrationReducer'

const messages = defineMessages({
  name: {
    id: 'form.name_placeholder',
    defaultMessage: 'Ваше имя',
  },
})

export const EnterName: FC = () => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const nameRef = useRef<HTMLInputElement>(null)
  const { name, formErrors } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { name },
        formErrors,
      },
    }) => ({ name, formErrors })
  )
  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus()
    }
  }, [])

  const handleChange: AppInputChangeHandler = (event) => {
    const { name, value } = resolveFormFieldValue(event.target)
    dispatch(
      updateRegistrationFieldAction(
        name as StepRegistrationFieldList,
        value as string
      )
    )
  }
  const handleSubmit = () => dispatch(nextStepAction(enterNamePath))

  return (
    <StepContent path={enterNamePath}>
      <Form id={'stepRegistration'} onSubmit={handleSubmit}>
        <StyledModalTitle>
          <FormattedMessage id={'dating_profile.name.header'} />
        </StyledModalTitle>
        <Field>
          <LargeInput
            name={StepRegistrationFieldList.name}
            type={'text'}
            ref={nameRef}
            value={name}
            maxLength={500}
            onChange={handleChange}
            placeholder={intl.formatMessage(messages.name)}
            required
            message={
              <InvalidMessage>
                <FormattedMessage id={'form.name_error'} />
              </InvalidMessage>
            }
          />
          {formErrors.name && (
            <InvalidMessage>{formErrors.name}</InvalidMessage>
          )}
        </Field>
        <SubmitGroup />
      </Form>
    </StepContent>
  )
}
