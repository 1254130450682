import React, { FC } from 'react'
import { useLayoutContext } from 'hooks/useLayoutContext'
import { RouterLink } from 'components/presentational/link'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { otherSocialNetworksPath } from 'components/page/Boarding/boarding.paths'
import { shortLimit } from 'components/page/Boarding/SocialNetworks/SocialNetworks.constants'
import styled from 'styled-components'
import { FormattedMessage } from 'react-intl'
import { FontSize } from 'common-constants/sizes'
import { EtcSvg } from 'components/presentational/svg/EtcSvg'
import { isMamba } from 'common/constants'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useRouteMatch } from 'react-router'

export const OtherSocial: FC<{ limit?: number; iconSize?: number }> = ({
  limit = shortLimit,
  iconSize,
}) => {
  const match = useRouteMatch()
  const { partnerId } = useShallowEqualSelector(
    ({ systemReducer: { partnerId } }) => ({
      partnerId,
    })
  )

  return (
    <StyledRouterLink
      to={mergeAllUrls(match.url, otherSocialNetworksPath, limit)}
      data-name={'other-social-1'}
    >
      {isMamba(partnerId) ? (
        <FormattedMessage id="app.more" defaultMessage="Ещё" />
      ) : (
        <EtcSvg size={iconSize} />
      )}
    </StyledRouterLink>
  )
}

const StyledRouterLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  text-transform: lowercase;
  font-size: ${FontSize.titleName}px;
`
