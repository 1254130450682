import { linkColor, RouterLink } from 'components/presentational/link'
import React, {
  CSSProperties,
  FC,
  FormEvent,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useState,
} from 'react'
import { CheckboxMarkSvg } from 'components/presentational/svg/CheckboxMarkSvg'
import styled from 'styled-components'
import {
  checkboxActive,
  checkboxDisabled,
  checkboxHover,
  disabledColor,
  disabledIconColor,
} from 'common/constants'
import { WithDataName } from 'common/types'
import { Colors } from 'common-constants/colors'

const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const CheckboxElement = styled.div<{
  $color?: string
}>`
  display: block;
  position: relative;
  background-color: transparent;
  outline: none;
  margin: 0;
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => props.$color};

  border-radius: 5px;

  input[type='checkbox'] + & {
    border: solid 1.5px ${({ $color }) => ($color ? $color : disabledIconColor)};
  }

  input[type='checkbox']:hover + & {
    border-color: ${checkboxHover};
    cursor: pointer;
  }

  input[type='checkbox']:active + &,
  input[type='checkbox']:focus + & {
    border-color: ${checkboxActive};
  }

  input[type='checkbox'][disabled] + &,
  input[type='checkbox'][disabled]:hover + & {
    border-color: ${checkboxDisabled};
  }

  input[type='checkbox']:checked + & {
    background-color: ${linkColor};
    border-color: ${linkColor};
  }

  input[type='checkbox']:checked:hover + & {
    background-color: ${checkboxHover};
    border-color: ${checkboxHover};
  }

  input[type='checkbox']:checked:focus + & {
    background-color: ${checkboxActive};
    border-color: ${checkboxActive};
  }

  input[type='checkbox'][disabled] + & {
    color: ${disabledColor};
  }

  input[type='checkbox']:checked[disabled] + & {
    background-color: ${Colors.disabledCheckBoxWithChecked};
    border-color: ${Colors.disabledCheckBoxWithChecked};
  }

  + ${IconWrapper} {
    color: white;
    opacity: 0;
    pointer-events: none;
  }

  input[type='checkbox']:checked + & + ${IconWrapper} {
    opacity: 1;
  }

  input[type='checkbox']:checked[disabled] + & + ${IconWrapper} {
    color: ${Colors.disabledCheckBoxWithCheckedColor};
  }

  input[type='checkbox'] + &,
  input[type='checkbox']:hover + & {
    transition: 150ms ease background-color, 150ms ease border-color;
  }
`

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const WrapperCheckBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const PlaceHolder = styled.span<{ indent: number }>`
  ${(props) => props.theme.marginLeft}: ${(props) => props.indent}px;
`

PlaceHolder.defaultProps = {
  indent: 5,
}

export const CheckboxWrapper: FC<
  {
    name: string
    checked: boolean
    disabled?: boolean
    required?: boolean
    size?: number
    color?: string
    style?: CSSProperties
    onChange: (event: SyntheticEvent) => void
  } & WithDataName
> = ({
  name,
  checked,
  disabled,
  required,
  size = 24,
  color,
  style,
  onChange,
  ...rest
}) => {
  const [invalid, setInvalid] = useState(false)

  const handleChange = (event: SyntheticEvent) => {
    onChange(event)
    setInvalid(false)
  }

  const handleInvalid = (event: FormEvent<HTMLInputElement>) => {
    event.preventDefault()
    if (required) {
      setInvalid(true)
    }
  }

  const elementStyle: CSSProperties = { width: size, height: size }

  if (invalid) {
    elementStyle.borderColor = Colors.warning
  }

  return (
    <WrapperCheckBox style={style}>
      <input
        type={'checkbox'}
        name={name}
        data-name={rest['data-name']}
        checked={checked}
        onChange={handleChange}
        onInvalid={handleInvalid}
        disabled={disabled}
        required={required}
        value={String(checked)}
        style={{ opacity: 0, position: 'absolute' }}
      />
      <CheckboxElement
        {...rest}
        style={elementStyle}
        $color={invalid ? Colors.warning : color}
        tabIndex={0}
        role="checkbox"
        aria-checked={checked}
      />
      <IconWrapper>
        <CheckboxMarkSvg
          width={Math.round(size * 0.55)}
          height={Math.round(size * 0.4)}
        />
      </IconWrapper>
    </WrapperCheckBox>
  )
}

export const Checkbox: FC<
  {
    name: string
    checked?: boolean
    disabled?: boolean
    required?: boolean
    href?: string
    placeholder?: ReactNode | string
    colorCheckBox?: string
    withImage?: boolean
    size?: number
    style?: CSSProperties
    elementStyle?: CSSProperties
    onChange: (event: SyntheticEvent) => void
    onClick?: (event: SyntheticEvent) => void
    placeHolderMargin?: number
  } & WithDataName &
    PropsWithChildren
> = ({
  name,
  checked,
  onChange,
  onClick,
  disabled,
  href,
  placeholder = '',
  'data-name': dataName,
  colorCheckBox = 'rgba(0, 0, 0, 0.1)',
  withImage,
  size,
  style,
  elementStyle,
  children,
  placeHolderMargin = 5,
  ...rest
}) => {
  const checkboxStyle = { ...rest, style: elementStyle }

  if (disabled && href) {
    return (
      <RouterLink to={href}>
        <CheckboxWrapper
          {...checkboxStyle}
          name={name}
          data-name={dataName}
          checked={Boolean(checked)}
          onChange={onChange}
          disabled={disabled}
          color={colorCheckBox}
        />
      </RouterLink>
    )
  }

  return (
    <Wrapper style={style}>
      <CheckboxWrapper
        {...checkboxStyle}
        name={name}
        data-name={dataName}
        checked={Boolean(checked)}
        size={size}
        onChange={onChange}
        disabled={disabled}
      />
      {Boolean(placeholder) && (
        <PlaceHolder indent={placeHolderMargin}>{placeholder}</PlaceHolder>
      )}
      {children}
    </Wrapper>
  )
}
