import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { Gender } from 'common-constants/api6.types'
import { FormattedMessage, useIntl } from 'react-intl'
import React, { useEffect } from 'react'
import { fetchRegistrationSearchResultAction } from 'actions/form/stepRegistrationAction'
import { useDispatch } from 'react-redux'

export const TitleText = () => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const { targetGender, searchTotal } = useShallowEqualSelector(
    ({ stepRegistration: { targetGender, searchTotal } }) => ({
      targetGender,
      searchTotal,
    })
  )
  useEffect(() => {
    dispatch(fetchRegistrationSearchResultAction())
  }, [dispatch])

  const values = {
    valueNumber: searchTotal,
    value: intl.formatNumber(searchTotal),
  }
  if (targetGender === Gender.Any) {
    return (
      <FormattedMessage
        id={'registration.step.found.any'}
        defaultMessage={
          'Найдено {value} {valueNumber, plural, one {человек} few {человека} many {человек} other {человек}}'
        }
        values={values}
      />
    )
  }
  if (targetGender === Gender.Female) {
    return (
      <FormattedMessage
        id={'registration.step.found.female'}
        defaultMessage={
          'Найдено {value} {valueNumber, plural, one {женщина} few {женщины} many {женщин} other {женщин}}'
        }
        values={values}
      />
    )
  }
  return (
    <FormattedMessage
      id={'registration.step.found.male'}
      defaultMessage={
        'Найдено {value} {valueNumber, plural, one {мужчина} few {мужчины} many {мужчин} other {мужчин}}'
      }
      values={values}
    />
  )
}
