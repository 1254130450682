import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useDispatch } from 'react-redux'
import {
  nextStepAction,
  updateRegistrationGenderAction,
} from 'actions/form/stepRegistrationAction'
import { createDecisionAnimationClickHandler } from 'components/presentational/button/DecisionButton'
import { Gender } from 'common-constants/api6.types'
import { yourGenderPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import styled from 'styled-components'
import { media } from 'components/presentational'
import { LogoIndex } from 'components/presentational/Logo/LogoIndex'
import { DecisionButtonDark } from 'components/presentational/button/DecisionButtonDark'

export const SelectYourGender: FC = () => {
  const dispatch = useDispatch()
  const handleSelectGender = (value: Gender) =>
    createDecisionAnimationClickHandler(
      () => dispatch(updateRegistrationGenderAction(value)),
      () => dispatch(nextStepAction(yourGenderPath))
    )

  return (
    <StepContent path={yourGenderPath}>
      <LogoIndex alternativeLogo={true} />
      <ContentWrapper>
        <StyledModalTitle>
          <FormattedMessage
            id={'registration.step.gender'}
            defaultMessage={'Укажите свой пол'}
          />
        </StyledModalTitle>
        <DecisionItemsWrapper>
          <DecisionButtonDark
            onClick={handleSelectGender(Gender.Male)}
            data-name={`gender-me-${Gender.Male}-action`}
          >
            <FormattedMessage id={'search.settings.whoiam.M'} />
          </DecisionButtonDark>
          <DecisionButtonDark
            onClick={handleSelectGender(Gender.Female)}
            data-name={`gender-me-${Gender.Female}-action`}
          >
            <FormattedMessage id={'search.settings.whoiam.F'} />
          </DecisionButtonDark>
        </DecisionItemsWrapper>
      </ContentWrapper>
    </StepContent>
  )
}

const DecisionItemsWrapper = styled.div`
  display: flex;
  margin-top: 25px;
  gap: 9px;

  & > * {
    width: calc(50% - 4.5px);
  }
`

const ContentWrapper = styled.div`
  ${media.phone`
    margin-top: auto;
    margin-bottom: auto;

    ${StyledModalTitle} {
      margin-top: unset;
    }
  `}
`
