import React from 'react'

export const EmailSvg = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="22"
    height="15"
    viewBox="0 0 22 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.8646 2.96726L14.3784 7.94024L18.9332 12.489C19.0439 12.5994 19.0519 12.7796 18.9516 12.9002C18.8552 13.016 18.6898 13.039 18.5671 12.9536L13.0745 9.13411L11.633 10.4985C11.2365 10.8739 10.6264 10.8718 10.2323 10.4936L8.81535 9.13411L3.61231 12.7545C3.47287 12.8515 3.28505 12.8265 3.17426 12.6961C3.05612 12.5569 3.06591 12.3465 3.19642 12.2196L7.56949 7.97008L2.11208 2.9671C1.92762 2.7979 1.91588 2.505 2.08624 2.32073C2.23899 2.15548 2.48764 2.13419 2.66482 2.27121L10.9594 8.68637L19.3549 2.31581C19.5234 2.18806 19.7583 2.21274 19.8983 2.37282C20.0496 2.54605 20.0345 2.81331 19.8646 2.96726M19.7814 0H2.21863C0.993366 0 0 1.02323 0 2.28532V12.7147C0 13.9769 0.993366 15 2.21863 15H19.7814C21.0067 15 22 13.9769 22 12.7147V2.28532C22 1.02323 21.0067 0 19.7814 0"
      fill="white"
    ></path>
  </svg>
)
