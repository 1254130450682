import React, { FC, useEffect } from 'react'
import { LocaleSwitch } from 'components/system/LocaleSwitch'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import {
  birthdayPath,
  datingGoalPath,
  personHeightPath,
  constitutionPath,
  redirectPath,
  selectTargetGenderPath,
  enterNamePath,
  selectAgePath,
  personWeightPath,
  finalStepPath,
  yourGenderPath,
  ageRestrictionPath,
} from 'components/page/StepRegistration/StepRegistration.paths'
import { EnterName } from 'components/page/StepRegistration/step/EnterName'
import { useReducersInsert } from 'hooks/useReducersInsert'
import { stepRegistrationReducer } from 'reducers/registration/stepRegistrationReducer'
import { SelectYourGender } from 'components/page/StepRegistration/step/SelectYourGender'
import { EnterBirthday } from 'components/page/StepRegistration/step/EnterBirthday'
import { fetchRegistrationSearchSettingsAction } from 'actions/form/stepRegistrationAction'
import { SelectDatingGoals } from 'components/page/StepRegistration/step/SelectDatingGoals'
import { SelectAge } from 'components/page/StepRegistration/step/SelectAge'
import { SelectConstitution } from 'components/page/StepRegistration/step/SelectConstitution'
import { SelectTargetGender } from 'components/page/StepRegistration/step/SelectTargetGender'
import { SelectHeight } from 'components/page/StepRegistration/step/SelectHeight'
import { SelectWeight } from 'components/page/StepRegistration/step/SelectWeight'
import { Final } from 'components/page/StepRegistration/step/Final'
import { VideoBackgroundModalLayout } from 'components/layout/VideoLayout/VideoBackgroundModalLayout'
import { Body } from 'components/layout/VideoLayout/VideoLayout.styled'
import { Modal } from 'components/layout/ModalLayout/ModalLayoutIndex'
import { media, sizes } from 'components/presentational'
import { EnterEmail } from './step/EnterEmail'
import { mergeAllUrls } from 'functions/mergeAllUrls'
import { Replace } from 'components/system/Replace'
import { AgeRestriction } from 'components/page/StepRegistration/step/AgeRestriction'
import { enterEmailPath } from 'components/paths'
import { useLocale } from 'hooks/useLocale'
import { useLayoutContext } from 'hooks/useLayoutContext'

const StepSwitch: FC = () => {
  const locale = useLocale()
  const { baseUrl } = useLayoutContext()

  return (
    <LocaleSwitch
      matchPath={mergeAllUrls(locale)}
      routes={[
        {
          path: enterNamePath,
          component: EnterName,
          exact: false,
        },
        {
          path: yourGenderPath,
          component: () => (
            <Replace
              to={mergeAllUrls(baseUrl, redirectPath)}
              uid="Switch-yourGenderPath"
            />
          ),
          exact: false,
        },
        {
          path: birthdayPath,
          component: EnterBirthday,
          exact: false,
        },
        {
          path: selectTargetGenderPath,
          component: SelectTargetGender,
          exact: false,
        },
        {
          path: selectAgePath,
          component: SelectAge,
          exact: false,
        },
        {
          path: datingGoalPath,
          component: SelectDatingGoals,
          exact: false,
        },
        {
          path: constitutionPath,
          component: SelectConstitution,
          exact: false,
        },
        {
          path: personHeightPath,
          component: SelectHeight,
          exact: false,
        },
        {
          path: personWeightPath,
          component: SelectWeight,
          exact: false,
        },
        {
          path: finalStepPath,
          component: Final,
          exact: false,
        },
        {
          path: enterEmailPath,
          component: EnterEmail,
          exact: false,
        },
        {
          path: ageRestrictionPath,
          component: AgeRestriction,
          exact: false,
        },
        {
          path: redirectPath,
          component: SelectYourGender,
          exact: false,
        },
      ]}
    />
  )
}

/**
 * До выхода задачи вставляю через стилизацию, без выноса CSS
 * https://redmine.mamba.ru/issues/108780
 */
const StyledModal = styled(Modal)`
  @media (min-width: ${sizes.phone + 1}px) {
    &&& {
      margin-top: 40px;
      justify-content: flex-start;
    }
  }
  ${media.phone`
    flex-grow: 1;    
  `}
`

const StepRegistrationIndex: FC = () => {
  useReducersInsert({ stepRegistration: stepRegistrationReducer })
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchRegistrationSearchSettingsAction())
  }, [dispatch])

  return (
    <VideoBackgroundModalLayout>
      <Body>
        <StyledModal>
          <StepSwitch />
        </StyledModal>
      </Body>
    </VideoBackgroundModalLayout>
  )
}

export default StepRegistrationIndex
