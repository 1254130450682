import React, { FC, PropsWithChildren } from 'react'
import { FormNote } from 'components/presentational/form'

export const CenterNote: FC<{ size?: number } & PropsWithChildren> = ({
  size = 16,
  children,
}) => {
  return (
    <FormNote size={size} style={{ textAlign: 'center' }}>
      {children}
    </FormNote>
  )
}
