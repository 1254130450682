import React, { FC, SVGProps } from 'react'

export const Age18PlusSvg: FC<
  {
    width?: number
    height?: number
  } & SVGProps<SVGSVGElement>
> = ({ width = 112, height = 124, ...rest }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 112 124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#a)">
      <path
        d="M100.357 124h-88.53A11.794 11.794 0 0 1 0 112.174L.117 11.709C.117 5.27 5.387 0 11.944 0h88.53c6.441 0 11.828 5.27 11.828 11.826l-.117 100.465c-.117 6.44-5.387 11.709-11.828 11.709Z"
        fill="#000"
      />
      <path
        d="M27.14 20.355C21.875 27.608 14.506 33.69 5.264 38.604l-2.105.935v14.974l16.026-8.773v56.15h15.09V19.887h-6.901l-.234.468ZM76.623 58.724c1.988-1.637 3.86-3.86 5.615-6.901 2.105-3.744 3.275-7.37 3.275-10.763 0-6.434-1.988-11.58-5.849-15.441-3.86-3.744-9.358-5.732-16.26-5.732-6.434 0-11.698 1.871-15.793 5.732-4.094 3.86-6.2 9.007-6.2 15.441 0 3.393.936 6.785 2.69 10.178 1.522 2.807 3.276 5.264 5.499 7.136-2.925 2.222-5.498 5.03-7.37 8.54-2.222 3.977-3.392 8.07-3.392 12.282 0 7.955 2.105 14.155 6.434 18.483 4.211 4.328 10.294 6.434 18.015 6.434 7.604 0 13.804-2.223 18.249-6.55 4.445-4.33 6.785-10.53 6.785-18.367 0-3.86-1.404-7.955-4.095-12.283-2.456-3.743-4.913-6.434-7.603-8.188Zm-13.22-24.566c2.107 0 3.861.585 5.148 1.755 1.287 1.17 1.872 2.808 1.872 4.796 0 1.872-.702 3.978-1.989 6.317-1.17 2.106-2.457 3.627-3.86 4.446-1.872-.936-3.627-2.34-5.381-4.446-1.755-2.222-2.691-4.328-2.691-6.317-.117-4.445 2.106-6.55 6.902-6.55Zm6.903 52.876c-1.872 1.755-4.212 2.573-7.253 2.573-2.808 0-4.913-.935-6.668-2.924-1.755-1.989-2.574-4.562-2.574-7.72 0-5.499 2.69-10.061 8.189-13.688 7.37 4.914 11.113 9.827 11.113 14.74 0 3.041-.936 5.264-2.807 7.019Z"
        fill="#fff"
      />
      <path
        d="M108.909 58.257h-10.06V48.43h-5.147v9.827h-9.944v5.147h9.944v9.943h5.147v-9.943h10.06v-5.147Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h112v124H0z" />
      </clipPath>
    </defs>
  </svg>
)
