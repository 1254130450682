import {
  nextStepAction,
  setPersonWeightAction,
} from 'actions/form/stepRegistrationAction'
import {
  maximumWeight,
  minimumWeight,
} from 'components/page/MainSearch/weightHasNotDefaultValue'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { personWeightPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { createDecisionAnimationClickHandler } from 'components/presentational/button/DecisionButton'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { NumberRange } from 'reducers/registration/stepRegistrationReducer'
import { DecisionItem } from '../StepRegistration.styled'
import { StyledDecisionButtonPlain } from './styled'

interface WeightVariant {
  name: ReactNode
  value: NumberRange
}

const weights: WeightVariant[] = [
  {
    name: (
      <FormattedMessage
        id={'registration.step.weight.low'}
        defaultMessage={'До {value} кг'}
        values={{ value: 60 }}
      />
    ),
    value: { from: minimumWeight, to: 60 },
  },
  {
    name: (
      <FormattedMessage
        id={'registration.step.weight.middle'}
        defaultMessage={'От {from} до {to} кг'}
        values={{ from: 60, to: 80 }}
      />
    ),
    value: { from: 60, to: 80 },
  },
  {
    name: (
      <FormattedMessage
        id={'registration.step.weight.middle'}
        defaultMessage={'От {from} до {to} кг'}
        values={{ from: 80, to: 100 }}
      />
    ),
    value: { from: 80, to: 100 },
  },
  {
    name: (
      <FormattedMessage
        id={'registration.step.weight.high'}
        defaultMessage={'Больше {value} кг'}
        values={{ value: 100 }}
      />
    ),
    value: { from: 100, to: maximumWeight },
  },
  {
    name: (
      <FormattedMessage
        id={'app.any2'}
        defaultMessage={'Любого'}
        values={{ value: null }}
      />
    ),
    value: { from: null, to: null },
  },
]

export const SelectWeight: FC = () => {
  const dispatch = useDispatch()
  const { targetGender } = useShallowEqualSelector(
    ({ stepRegistration: { targetGender } }) => ({
      targetGender,
    })
  )

  const createSelectWeigthHandler = (value: NumberRange) => {
    return createDecisionAnimationClickHandler(
      () => dispatch(setPersonWeightAction(value)),
      () => dispatch(nextStepAction(personWeightPath))
    )
  }

  return (
    <StepContent path={personWeightPath}>
      <StyledModalTitle>
        <FormattedMessage
          id={'registration.step.weight'}
          defaultMessage={
            '{gender, select, M {Мужчин} F {Женщин} other {Людей}} какого веса вы ищете?'
          }
          values={{
            gender: targetGender,
          }}
        />
      </StyledModalTitle>

      {weights.map(({ name, value }, index) => (
        <DecisionItem key={index}>
          <StyledDecisionButtonPlain
            data-name={`person-weight-${value.from}-${value.to}-action`}
            onClick={createSelectWeigthHandler(value)}
            lowercase
          >
            {name}
          </StyledDecisionButtonPlain>
        </DecisionItem>
      ))}
    </StepContent>
  )
}
