import {
  nextStepAction,
  setPersonHeightAction,
} from 'actions/form/stepRegistrationAction'
import {
  maximumHeight,
  minimumHeight,
} from 'components/page/MainSearch/heightHasNotDefaultValue'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { personHeightPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { createDecisionAnimationClickHandler } from 'components/presentational/button/DecisionButton'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { NumberRange } from 'reducers/registration/stepRegistrationReducer'
import { DecisionItem } from '../StepRegistration.styled'
import { StyledDecisionButtonPlain } from './styled'

interface HeightVariant {
  name: ReactNode
  value: NumberRange
}

const heights: HeightVariant[] = [
  {
    name: (
      <FormattedMessage
        id={'registration.step.height.low'}
        defaultMessage={'До {value} см'}
        values={{ value: 170 }}
      />
    ),
    value: { from: minimumHeight, to: 170 },
  },
  {
    name: (
      <FormattedMessage
        id={'registration.step.height.middle'}
        defaultMessage={'От {from} до {to} см'}
        values={{ from: 170, to: 190 }}
      />
    ),
    value: { from: 170, to: 190 },
  },
  {
    name: (
      <FormattedMessage
        id={'registration.step.height.high'}
        defaultMessage={'Выше {value} см'}
        values={{ value: 190 }}
      />
    ),
    value: { from: 190, to: maximumHeight },
  },
  {
    name: (
      <FormattedMessage
        id={'app.any2'}
        defaultMessage={'Любого'}
        values={{ value: null }}
      />
    ),
    value: { from: null, to: null },
  },
]

export const SelectHeight: FC = () => {
  const dispatch = useDispatch()
  const { targetGender } = useShallowEqualSelector(
    ({ stepRegistration: { targetGender } }) => ({
      targetGender,
    })
  )

  const createSelectHeigthHandler = (value: NumberRange) => {
    return createDecisionAnimationClickHandler(
      () => dispatch(setPersonHeightAction(value)),
      () => dispatch(nextStepAction(personHeightPath))
    )
  }

  return (
    <StepContent path={personHeightPath}>
      <StyledModalTitle>
        <FormattedMessage
          id={'registration.step.height'}
          defaultMessage={
            '{gender, select, M {Мужчин} F {Женщин} other {Людей}} какого роста вы ищете?'
          }
          values={{
            gender: targetGender,
          }}
        />
      </StyledModalTitle>
      {heights.map(({ name, value }, index) => (
        <DecisionItem key={index}>
          <StyledDecisionButtonPlain
            data-name={`person-height-${value.from}-${value.to}-action`}
            onClick={createSelectHeigthHandler(value)}
            lowercase
          >
            {name}
          </StyledDecisionButtonPlain>
        </DecisionItem>
      ))}
    </StepContent>
  )
}
