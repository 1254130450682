import {
  nextStepAction,
  updateRegistrationFieldAction,
} from 'actions/form/stepRegistrationAction'
import { validateBirthdateAction } from 'actions/registration/validateBirthdateAction'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { birthdayPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { DecisionItem } from 'components/page/StepRegistration/StepRegistration.styled'
import { SubmitGroup } from 'components/page/StepRegistration/SubmitGroup'
import { Form, resolveFormFieldValue } from 'components/presentational/form'
import { BirthdaySelect } from 'components/presentational/input/BirthdaySelect'
import { CenterNote } from 'components/presentational/note/CenterNote'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { addZeroBeforeTimeUnit } from 'functions/number/addZeroBeforeTimeUnit'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, SyntheticEvent, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { StepRegistrationFieldList } from 'reducers/registration/stepRegistrationReducer'

export const EnterBirthday: FC = () => {
  const dispatch = useDispatch()
  const {
    form: { day, month, year },
  } = useShallowEqualSelector(({ stepRegistration: { form } }) => ({ form }))
  const handleChange = (event: SyntheticEvent) => {
    const { name, value } = resolveFormFieldValue(event.target)
    dispatch(
      updateRegistrationFieldAction(
        name as StepRegistrationFieldList,
        value as string
      )
    )
  }

  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    setLoading(true)
    const _month = addZeroBeforeTimeUnit(Number(month))
    const _day = addZeroBeforeTimeUnit(Number(day))
    const birthday = `${year}-${_month}-${_day}`

    const response = await dispatch(validateBirthdateAction(birthday))

    if (response['result']) {
      dispatch(nextStepAction(birthdayPath))
    }

    setLoading(false)
  }

  return (
    <StepContent path={birthdayPath}>
      <Form id={'stepRegistration'} onSubmit={handleSubmit}>
        <StyledModalTitle>
          <FormattedMessage
            id={'dating_profile.birth_date.header'}
            defaultMessage={'Когда у вас день рождения?'}
          />
        </StyledModalTitle>
        <DecisionItem>
          <CenterNote>
            <FormattedMessage
              id={'registration.step.age_note'}
              defaultMessage={
                'Для того, чтобы пройти регистрацию, вам должно быть не меньше 18 лет.'
              }
            />
          </CenterNote>
        </DecisionItem>
        <DecisionItem>
          <BirthdaySelect
            day={day}
            month={month}
            year={year}
            onChange={handleChange}
          />
        </DecisionItem>
        <SubmitGroup loading={loading} />
      </Form>
    </StepContent>
  )
}
