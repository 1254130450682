import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useDispatch } from 'react-redux'
import {
  nextStepAction,
  selectRegistrationTargetGenderAction,
} from 'actions/form/stepRegistrationAction'
import { DecisionItem } from 'components/page/StepRegistration/StepRegistration.styled'
import { Gender } from 'common-constants/api6.types'
import { createDecisionAnimationClickHandler } from 'components/presentational/button/DecisionButton'
import { SpaceSeparator } from 'components/presentational/separator'
import { selectTargetGenderPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { StyledDecisionButtonPlain } from './styled'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import { useLgbtDetail } from 'hooks/useLgbtDetail'
import { useLexemes } from './SelectTargetGender.useLexemes'

export const SelectTargetGender: FC = () => {
  const dispatch = useDispatch()

  const { gender } = useShallowEqualSelector(
    ({ stepRegistration: { gender } }) => ({
      gender,
    })
  )

  const { lgbtModified } = useLgbtDetail(gender!)

  const handleSelectGender = (gender: Gender) =>
    createDecisionAnimationClickHandler(
      () => dispatch(selectRegistrationTargetGenderAction(gender)),
      () => dispatch(nextStepAction(selectTargetGenderPath))
    )

  const { maleLexeme, femaleLexeme } = useLexemes(lgbtModified, gender!)

  return (
    <StepContent path={selectTargetGenderPath}>
      <StyledModalTitle>
        <FormattedMessage
          id={'registration.step.target.gender'}
          defaultMessage={'С кем вы хотите знакомиться?'}
        />
      </StyledModalTitle>
      <DecisionItem>
        <StyledDecisionButtonPlain
          onClick={handleSelectGender(Gender.Male)}
          data-name={`gender-want-${Gender.Male}-action`}
        >
          {maleLexeme}
        </StyledDecisionButtonPlain>
      </DecisionItem>
      <SpaceSeparator />
      <DecisionItem>
        <StyledDecisionButtonPlain
          onClick={handleSelectGender(Gender.Female)}
          data-name={`gender-want-${Gender.Female}-action`}
        >
          {femaleLexeme}
        </StyledDecisionButtonPlain>
      </DecisionItem>
      <SpaceSeparator />
      <DecisionItem>
        <StyledDecisionButtonPlain
          onClick={handleSelectGender(Gender.Any)}
          data-name={`gender-want-${Gender.Any}-action`}
        >
          <FormattedMessage
            id={'registration.step.target.any'}
            defaultMessage={'Со всеми'}
          />
        </StyledDecisionButtonPlain>
      </DecisionItem>
    </StepContent>
  )
}
