import React, { FC } from 'react'
import { BaseButton } from 'components/presentational/button'
import { DefaultTheme, ThemeProvider } from 'styled-components'
import { Colors } from 'common-constants/colors'
import { ButtonProps } from 'components/presentational/button/button.types'
import { timeout } from 'functions/timeout'

export const DecisionButton: FC<ButtonProps> = (props) => {
  return (
    <ThemeProvider
      theme={
        ({
          background: Colors.backgroundLight,
          hoverBackground: Colors.backgroundHoverLight,
          color: Colors.defaultAction,
          hoverColor: Colors.defaultAction,
          disabledColor: Colors.hoverColor,
          disabledBackground: Colors.defaultAction,
          activeColor: Colors.hoverColor,
          activeBackground: Colors.defaultAction,
          // focusColor: Colors.defaultAction,
          // focusBackground: Colors.backgroundHoverLight,
          fontSize: 21,
          padding: '29px 30px 28px 31px',
        } as unknown) as DefaultTheme
      }
    >
      <BaseButton {...props} />
    </ThemeProvider>
  )
}

export const DecisionButtonPlain: FC<ButtonProps> = (props) => {
  return (
    <ThemeProvider
      theme={
        ({
          display: 'block',
          background: 'transparent',
          color: Colors.dark,
          hoverColor: Colors.defaultAction,
          disabledColor: Colors.hoverColor,
          disabledBackground: Colors.defaultAction,
          activeColor: Colors.hoverColor,
          activeBackground: Colors.defaultAction,
          fontSize: 21,
          padding: '0',
        } as unknown) as DefaultTheme
      }
    >
      <BaseButton {...props} />
    </ThemeProvider>
  )
}

export const createDecisionAnimationClickHandler = (
  before: () => void,
  after: () => void
) => async () => {
  before()
  await timeout(400)
  after()
}
