import styled from 'styled-components'

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  & > * + * {
    ${({ theme }) => theme.marginLeft}: 16px;
  }

  & > * {
    ${({ theme }) => theme.marginRight}: 0;
  }
`
