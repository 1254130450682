import { Colors } from 'common-constants/colors'
import { DecisionButtonPlain } from 'components/presentational/button/DecisionButton'
import styled from 'styled-components'

export const StyledDecisionButtonPlain = styled(DecisionButtonPlain)<{
  lowercase?: boolean
}>`
  ${({ lowercase }) => lowercase && 'text-transform: lowercase'};

  /**
    * @see https://redmine.mamba.ru/issues/119433
   */
  border-radius: 0;

  &,
  &:hover,
  &:active {
    background-color: unset;
    color: ${Colors.dark};
  }

  @media (hover: hover) {
    &:hover,
    &:active {
      color: ${Colors.link};
    }
  }
`
