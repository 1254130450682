import React, { FC } from 'react'

export const CheckboxMarkSvg: FC<{
  width?: number
  height?: number
  className?: string
}> = ({ width = 13, height = 10, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 13 10"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 4.5L0 6L4 10L13 1.5L11.5 0L4 7L1.5 4.5Z"
      fill="currentColor"
    />
  </svg>
)
