import {
  nextStepAction,
  setDatingGoalAction,
} from 'actions/form/stepRegistrationAction'
import { DatingGoals } from 'common-constants/api6.types'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { datingGoalPath } from 'components/page/StepRegistration/StepRegistration.paths'
import { DecisionItem } from 'components/page/StepRegistration/StepRegistration.styled'
import { createDecisionAnimationClickHandler } from 'components/presentational/button/DecisionButton'
import { CenterNote } from 'components/presentational/note/CenterNote'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { capitalizeFirstLetter } from 'functions/capitalizeFirstLetter'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import { StyledDecisionButtonPlain } from './styled'

const allowedGoals = [
  DatingGoals.Meet,
  DatingGoals.Flirtation,
  DatingGoals.Love,
  DatingGoals.SeriousRelationship,
  DatingGoals.FriendlyCommunication,
  DatingGoals.FlirtationAndDating,
  DatingGoals.WillDecideWhenMeet,
]

export const SelectDatingGoals: FC = () => {
  const dispatch = useDispatch()
  const { target = [] } = useShallowEqualSelector(
    ({
      stepRegistration: {
        variants: { target },
      },
    }) => ({ target })
  )

  const createSelectGoalHandler = (value: DatingGoals) =>
    createDecisionAnimationClickHandler(
      () => dispatch(setDatingGoalAction(value)),
      () => dispatch(nextStepAction(datingGoalPath))
    )

  return (
    <StepContent path={datingGoalPath}>
      <StyledModalTitle>
        <FormattedMessage
          id={'registration.step.purpose'}
          defaultMessage={'Какая у вас цель знакомства?'}
        />
      </StyledModalTitle>
      <DecisionItem>
        <CenterNote>
          <FormattedMessage
            id={'dating_profile.goal_note'}
            defaultMessage={
              'Ваши цели смогут видеть только те люди, у которых указаны такие же цели.'
            }
          />
        </CenterNote>
      </DecisionItem>

      {target
        .filter(({ value }) => allowedGoals.includes(value))
        .map(({ name, value }) => (
          <DecisionItem key={name}>
            <StyledDecisionButtonPlain
              data-name={`dating-purpose-${value}-action`}
              onClick={createSelectGoalHandler(value)}
            >
              {capitalizeFirstLetter(name)}
            </StyledDecisionButtonPlain>
          </DecisionItem>
        ))}
    </StepContent>
  )
}
