import { Gender } from 'common-constants/api6.types'
import React, { useLayoutEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

/**
 * Устанавливаем лексемы через хук, иначе при гидрации будет ошибка:
 *
 * Error
 * Text content does not match server-rendered HTML.
 *
 * Чтобы сервер сразу генерил правильный html, нужно передавать на сервер значение stepRegistration.gender,
 * которое хранится в local storage. Один(если не единственный) вариант это передавать это значение в куках.
 * Все это выглядит оверхедом и оверинжинирингом, поэтому подменяем лексемы через useLayoutEffect.
 */
export const useLexemes = (
  lgbtModified: boolean | undefined,
  gender: Gender
) => {
  const [male, setMale] = useState(createMaleLexeme(false, gender))
  const [female, setFemale] = useState(createFemaleLexeme(false, gender))

  useLayoutEffect(() => {
    setMale(createMaleLexeme(lgbtModified, gender))
    setFemale(createFemaleLexeme(lgbtModified, gender))
  }, [gender, lgbtModified])

  return { maleLexeme: male, femaleLexeme: female }
}

const createMaleLexeme = (
  lgbtModified: boolean | undefined,
  gender: Gender
) => {
  if (!lgbtModified) {
    return (
      <FormattedMessage
        id={'registration.step.target.male'}
        defaultMessage={'С мужчинами'}
      />
    )
  }

  if (gender === Gender.Male) {
    return (
      <FormattedMessage
        id={'registration.step.target.man.friend'}
        defaultMessage={'С другом'}
      />
    )
  }

  return (
    <FormattedMessage
      id={'registration.step.target.man'}
      defaultMessage={'С мужчиной'}
    />
  )
}

const createFemaleLexeme = (
  lgbtModified: boolean | undefined,
  gender: Gender
) => {
  if (!lgbtModified) {
    return (
      <FormattedMessage
        id={'registration.step.target.female'}
        defaultMessage={'С женщинами'}
      />
    )
  }

  if (gender === Gender.Female) {
    return (
      <FormattedMessage
        id={'registration.step.target.girl.friend'}
        defaultMessage={'С подругой'}
      />
    )
  }

  return (
    <FormattedMessage
      id={'registration.step.target.girl'}
      defaultMessage={'С девушкой'}
    />
  )
}
