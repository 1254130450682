import { stepRegisterWithEmailAction } from 'actions/form/stepRegisterWithEmailAction'
import { updateRegistrationFieldAction } from 'actions/form/stepRegistrationAction'
import { StepContent } from 'components/page/StepRegistration/StepContent'
import { LargeInput } from 'components/page/StepRegistration/StepRegistration.styled'
import { SubmitGroup } from 'components/page/StepRegistration/SubmitGroup'
import { enterEmailPath } from 'components/paths'
import {
  Field,
  Form,
  resolveFormFieldValue,
} from 'components/presentational/form'
import {
  AppInputChangeHandler,
  InvalidMessage,
} from 'components/presentational/input'
import { StyledModalTitle } from 'components/presentational/title/TextTitle'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useEffect, useRef } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useDispatch } from 'react-redux'
import {
  StepRegistrationFieldList,
  stepRegistrationReducer,
} from 'reducers/registration/stepRegistrationReducer'
import { useReducersInsert } from 'hooks/useReducersInsert'

const messages = defineMessages({
  email: {
    id: 'app.email',
    defaultMessage: 'Электронная почта',
  },
})

export const EnterEmail: FC = () => {
  useReducersInsert({ stepRegistration: stepRegistrationReducer })

  const intl = useIntl()
  const dispatch = useDispatch()
  const emailRef = useRef<HTMLInputElement>(null)
  const { email, formErrors, authorizing } = useShallowEqualSelector(
    ({
      stepRegistration: {
        form: { email },
        formErrors,
      },
      authorizationReducer: { authorizing },
    }) => ({ email, formErrors, authorizing })
  )
  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus()
    }
  }, [])
  const handleChange: AppInputChangeHandler = (event) => {
    const { name, value } = resolveFormFieldValue(event.target)
    dispatch(
      updateRegistrationFieldAction(
        name as StepRegistrationFieldList,
        value as string
      )
    )
  }
  const handleSubmit = () => {
    dispatch(stepRegisterWithEmailAction())
  }

  return (
    <StepContent path={enterEmailPath}>
      <Form id={'stepRegistration'} onSubmit={handleSubmit}>
        <StyledModalTitle>
          <FormattedMessage
            id="registration.step.email_enter"
            defaultMessage="Введите электронную почту"
          />
        </StyledModalTitle>
        <Field>
          <LargeInput
            name={StepRegistrationFieldList.email}
            type={'text'}
            ref={emailRef}
            value={email}
            maxLength={500}
            onChange={handleChange}
            placeholder={intl.formatMessage(messages.email)}
            required
            message={
              <InvalidMessage>
                <FormattedMessage id="form.email_error" />
              </InvalidMessage>
            }
          />
          {formErrors.email && (
            <InvalidMessage>{formErrors.email}</InvalidMessage>
          )}
        </Field>
        <SubmitGroup loading={authorizing} />
      </Form>
    </StepContent>
  )
}
