import React, { FC } from 'react'

export const EtcSvg: FC<{ size?: number }> = ({ size = 60 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 60 60"
  >
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#0079C0"
        strokeWidth="2"
        d="M59 30C59 13.984 46.016 1 29.998 1 13.984 1 1 13.985 1 30c0 16.017 12.983 29 28.998 29C46.017 59 59 46.018 59 30z"
      />
      <path
        fill="#0079C0"
        d="M22 30.078c0 1.699-1.345 3.079-3 3.079s-3-1.38-3-3.079C16 28.38 17.345 27 19 27s3 1.38 3 3.078m11 .002c0 1.7-1.344 3.08-3 3.08s-3-1.38-3-3.08c0-1.7 1.344-3.08 3-3.08s3 1.38 3 3.08m11-.002c0 1.699-1.346 3.079-3 3.079-1.655 0-3-1.38-3-3.079C38 28.38 39.345 27 41 27c1.654 0 3 1.38 3 3.078"
      />
    </g>
  </svg>
)
