import { FormattedMessage } from 'react-intl'
import { PlainLink } from './link'
import React, { FC } from 'react'
import { supportFormExtendPath } from 'components/paths'

export const UnexpectedServerErrorMessage: FC = () => (
  <FormattedMessage
    id={'app.error.unexpected_server_error'}
    defaultMessage={
      'Что-то пошло не так. Попробуйте ещё раз или обратитесь в {supportLink}'
    }
    values={{
      supportLink: (
        <PlainLink href={supportFormExtendPath}>
          <FormattedMessage
            id={'app.error.unexpected_server_error_link'}
            defaultMessage={'службу поддержки'}
          />
        </PlainLink>
      ),
    }}
  />
)
