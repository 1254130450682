import { OtherSocial } from 'components/page/Boarding/SocialNetworks/OtherSocial'
import { VendorItem } from 'components/page/Boarding/SocialNetworks/VendorItem'
import { DecisionItem } from 'components/page/StepRegistration/StepRegistration.styled'
import { appAgreementIndexPath, appConfidentialityPath } from 'components/paths'
import { Center } from 'components/presentational/layout/Center'
import { Row } from 'components/presentational/layout/Row'
import { ModalLink } from 'components/presentational/link'
import { CenterNote } from 'components/presentational/note/CenterNote'
import { useShallowEqualSelector } from 'hooks/useShallowEqualSelector'
import React, { FC, useMemo, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Checkbox } from 'components/presentational/checkbox/Checkbox'
import { SpaceSeparatorNext } from 'components/presentational/separator'
import { isMamba } from 'common/constants'
import { isRussia } from 'functions/country/country.functions'

const VENDORS_LIMIT = 4
const ICON_SIZE = 40

export const SocialNetworksNext: FC<{
  showAccept: boolean
}> = ({ showAccept }) => {
  const { vendors, partnerId, currentLocation } = useShallowEqualSelector(
    ({
      authorizationReducer: { vendors },
      systemReducer: { partnerId },
      location: { currentLocation },
    }) => ({
      vendors,
      partnerId,
      currentLocation,
    })
  )
  const [agree, setAgree] = useState(true)

  const showCheckbox = useMemo(
    () =>
      isMamba(partnerId) && !isRussia(currentLocation?.country!) && showAccept,
    [currentLocation, partnerId, showAccept]
  )

  const handleAgree = () => {
    setAgree((value) => !value)
  }

  const agreement = (
    <FormattedMessage
      id={'boarding.legal_conditions'}
      defaultMessage={
        'Регистрируясь, вы соглашаетесь и принимаете условия {agreement} и {confidentiality}'
      }
      values={{
        agreement: (
          <>
            {showCheckbox && <SpaceSeparatorNext width="5px" />}
            <ModalLink to={appAgreementIndexPath} data-name="agreement">
              <FormattedMessage
                id={'boarding.social_media_conditions_agreement'}
                defaultMessage={'Соглашения'}
              />
            </ModalLink>
          </>
        ),
        confidentiality: (
          <ModalLink to={appConfidentialityPath} data-name="confidentiality">
            <FormattedMessage
              id="boarding.confidentiality_link"
              defaultMessage="Политики в отношении обработки персональных данных"
            />
          </ModalLink>
        ),
      }}
    />
  )

  return (
    <div>
      <Center>
        <Row>
          {vendors.slice(0, VENDORS_LIMIT).map((vendor, index) => (
            <VendorItem vendor={vendor} key={index} size={ICON_SIZE} />
          ))}
          <OtherSocial limit={VENDORS_LIMIT} iconSize={ICON_SIZE} />
        </Row>
      </Center>

      <DecisionItem>
        <CenterNote size={12}>
          <FormattedMessage
            id={'registration.step.social_note'}
            defaultMessage={'В соцсетях ничего не будет опубликовано.'}
          />
          {showCheckbox ? (
            <Checkbox
              name="agree"
              checked={agree}
              required={true}
              size={12}
              onChange={handleAgree}
              style={{ display: 'block' }}
              elementStyle={{
                display: 'inline-block',
                position: 'relative',
                top: 3,
              }}
              data-name="agree-switcher"
            >
              <SpaceSeparatorNext
                width="5px"
                style={{
                  display: 'inline-block',
                }}
              />
              {agreement}
            </Checkbox>
          ) : (
            <div>{agreement}</div>
          )}
        </CenterNote>
      </DecisionItem>
    </div>
  )
}
