import React, { FC, SyntheticEvent } from 'react'
import { InvalidMessage } from 'components/presentational/input'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { DateSelect } from 'components/presentational/input/DateSelect'

const messages = defineMessages({
  day: { id: 'form.select.day', defaultMessage: 'День' },
  month: { id: 'form.select.month', defaultMessage: 'Месяц' },
  year: { id: 'form.select.year', defaultMessage: 'Год' },
})

export interface BirthdaySelectProps {
  day: string
  month: string
  year: string
  onChange: (event: SyntheticEvent) => void
}

export const BirthdaySelect: FC<BirthdaySelectProps> = ({
  day,
  month,
  year,
  onChange,
}) => {
  const intl = useIntl()
  return (
    <DateSelect
      dayPlaceholder={intl.formatMessage(messages.day)}
      monthPlaceholder={intl.formatMessage(messages.month)}
      yearPlaceholder={intl.formatMessage(messages.year)}
      dayValue={Number(day)}
      monthValue={Number(month)}
      yearValue={Number(year)}
      onChange={onChange}
      required
      message={
        <InvalidMessage>
          <FormattedMessage id={'app.error.birth_required'} />
        </InvalidMessage>
      }
    />
  )
}
